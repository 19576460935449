import DateFormat from "components/Fare/formatDate";
import {
    useFormatDate,
    useFormatDateTime,
    useFormatTime,
} from "hooks/useLocalization";
import * as moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { InvariantDateModel } from "WebApiClient";

export const DateDisplay: React.FC<{ date: Date | undefined }> = (props) => {
    const { date } = props;
    const localizedDate = useFormatDate(date);
    // console.log("local---", localizedDate);
    <DateFormat dates={localizedDate} />;
    // return <span>{currency} {new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency, currencyDisplay: "none" }).format(price)}</span>
    return <DateFormat dates={localizedDate} />;
};
export const InvariantDateDisplay: React.FC<{
    date: InvariantDateModel | undefined;
}> = (props) => {
    const { date } = props;
    let parsed: Date | undefined = undefined;
    if (date) {
        parsed = moment({
            day: date.day,
            month: date.month - 1,
            year: date.year,
        }).toDate();
    }
    const localizedDate = useFormatDate(parsed);
    // return <span>{currency} {new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency, currencyDisplay: "none" }).format(price)}</span>
    return <span>{localizedDate}</span>;
};
export const DateTimeDisplay: React.FC<{ date: Date | undefined }> = (
    props
) => {
    const { date } = props;
    const localizedDate = useFormatDateTime(date);
    // return <span>{currency} {new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency, currencyDisplay: "none" }).format(price)}</span>
    return <span>{localizedDate}</span>;
};
export const TimeDisplay: React.FC<{
    date: Date | undefined;
    showSeconds?: boolean;
}> = (props) => {
    const { date, showSeconds } = props;
    const { i18n } = useTranslation();
    const seconds = showSeconds !== undefined ? showSeconds : false;
    const localizedDate = useFormatTime(date, seconds);
    // return <span>{currency} {new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency, currencyDisplay: "none" }).format(price)}</span>
    return <span>{localizedDate}{i18n.language != 'de' && 'h'}</span>;
};
