import { faBug, faCheckCircle, faEnvelope, faExclamationTriangle, faExpand, faList, faPaste, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Environment } from "Constants";
import { useUserInformation } from "hooks/various";
import moment from 'moment';
import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { Alert, Col, Collapse, Row } from "reactstrap";
import { AdminEntityValidationResult, BaseApiResponseMetaData, ErrorDetail, TransactionInfo } from "WebApiClient";
import * as Moment from "moment";
import { GetErrorMessage } from "./Helpers/UniversalErrorText/UniversalErrorText";
import { useTranslation } from "react-i18next";


const TEST_APIPERFORMEDACTION_1: TransactionInfo = {
    errorCode: "",
    errorMessage: "",
    source: "AGT",
    success: true,
    title: "Fech some data",
    startTime: new Date(),
    endTime: new Date(),
    details: "",

}

const TEST_APIPERFORMEDACTION_2: TransactionInfo = {
    errorCode: "MOCK_ABC",
    errorMessage: "Something went wrong",
    source: "FAPI",
    success: false,
    title: "Fetch backend data",
    startTime: new Date(),
    endTime: new Date(),
    details: "",

}

const TEST_APIMETADATA: BaseApiResponseMetaData = {
    branchName: "myBranch",
    branchgroupName: "myBranchGroup",
    errorOccured: true,
    errorReason: TEST_APIPERFORMEDACTION_2,
    transactions: [TEST_APIPERFORMEDACTION_1, TEST_APIPERFORMEDACTION_2],
    transactionID: "54353tfge45z4z3434t334",
    requestReceivedTime: new Date(),
    requestTitle: "Do some stuff",
    responseSentTime: new Date(),
    sessionID: "233243543453564363456453",
    userName: "myUserName",
    webName: "myWeb",
    // reportString: "ACTION_REPORT",
    flightAPINode: "fapinode",
    environment: ""
}

export const TEST_APIMetaDataDisplay: React.FC<{}> = props => {
    return <APIMetaDataDisplay data={TEST_APIMETADATA} />
}

function CopyToClipboardAndAlert(content: string, title: string) {
    navigator.clipboard.writeText(content).then(() => { toast.success(title + " copied to clipboard.") });
}

export const ValidationErrorDisplay: React.FC<{ data: AdminEntityValidationResult }> = props => {
    const { errorSummary, isValid } = props.data;
    return <div>
        <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading mb-2"> <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid request or missing data. See details below.</h4>
            {errorSummary ?
                <Fragment>
                    <ul>
                        {errorSummary.map(((e, i) => <li key={"err_" + i}>{e}</li>))}
                    </ul>
                </Fragment> :
                <Fragment>
                    Validation failed. The is no further information available.
                </Fragment>
            }

        </div>
    </div>
}

export const ErrorDisplay: React.FC<{ data: BaseApiResponseMetaData | undefined, isHide: boolean }> = props => {
    const [discarded, setDiscarded] = useState(props?.isHide == true ? true : false);
    const { t } = useTranslation();

    const userInfo = useUserInformation();
    const expires = Moment.unix(userInfo.ValidUntil).toDate().getTime();
    const currentTime = new Date().getTime();

    const Toggle = () => {
        setDiscarded(!discarded);
    };

    return (
        <Row className="mb-2">
            <h4 className="text-danger mb-3 ">❌ Booking failed, please try again.</h4>
            {!discarded && (
                <Col colSpan={12}>
                    <div className="alert alert-danger" role="alert">
                        <div className="row">
                            <div className="col-12 mb-2">
                                <h4 className="alert-heading mb-2">
                                    <FontAwesomeIcon icon={faExclamationTriangle} /> An error has occurred{" "}
                                    {props.data ? (
                                        <span>
                                            during{" "}
                                            <span className="font-weight-bold">{props.data.requestTitle}</span>
                                        </span>
                                    ) : (
                                        <span>while processing your request.</span>
                                    )}
                                    <button className="btn btn-secondary btn-sm float-end" onClick={Toggle}>
                                        Hide
                                    </button>
                                </h4>
                            </div>
                            <div className="col-12 mb-2">
                                <div className="row">
                                    {props.data ? (
                                        <div className="col-12">
                                            <APIMetaDataDisplay data={props.data} />
                                        </div>
                                    ) : (
                                        <div className="col-12">
                                            {expires > currentTime
                                                ? GetErrorMessage("ERR_BACKEND_OFFLINE", t)
                                                : GetErrorMessage("ERR_SESSION_EXPIRED", t)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            )}
            {discarded && (
                <Col colSpan={12}>
                    <div className="d-grid">
                        <button className="btn btn-danger" onClick={Toggle}>
                            <FontAwesomeIcon icon={faExpand} /> Show error
                        </button>
                    </div>
                </Col>
            )}
        </Row>
    );
};

export const PaymentErrorDisplay: React.FC<{ data: ErrorDetail[] | undefined }> = props => {
    const [discarded, setDiscarded] = useState(true);
    function Toggle() {
        setDiscarded(!discarded);
    }
    console.log("data------", props.data);

    return (
        <Row className="mb-2">
            <Col xs={12}>
                {props.data && props.data.length > 0 ? (
                    <>
                        <h4 className="text-danger mb-3 ">❌ Payment failed. please try again.</h4>
                        {!discarded &&
                            <div className="alert alert-danger" role="alert">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <h5 className="mb-0">
                                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                                        {props.data[0].field === "payment.cardNumber"
                                            ? "Payment card number"
                                            : props.data[0].field || "Payment Error"}
                                    </h5>
                                    <button className="btn btn-sm btn-secondary" onClick={Toggle}>
                                        {discarded ? "Show details" : "Hide details"}
                                    </button>
                                </div>
                                {!discarded &&
                                    props.data.map((e: ErrorDetail, idx: number) => (
                                        <p key={idx} className="mb-1 fs-6">
                                            {e.field === "payment.cardNumber"
                                                ? "Please check your payment card number, CVV, and expiry date."
                                                : e.message}
                                        </p>
                                    ))}
                            </div>
                        }
                    </>
                ) : (
                    <div className="alert alert-danger" role="alert">
                        There seems to be no further information. Our backend services may be offline.
                        Please wait for a few minutes and try again. If this error persists, contact support.
                    </div>
                )}
            </Col>

            {discarded && props.data && props.data.length > 0 && (
                <Col xs={12}>
                    <div className="d-grid">
                        <button className="btn btn-danger" onClick={Toggle}>
                            <FontAwesomeIcon icon={faExpand} className="me-2" />
                            Show error
                        </button>
                    </div>
                </Col>
            )}
        </Row>
    );

}

export const TextWithCopyOption: React.FC<{ text: string, title: string }> = props => {
    return <span title="Click to copy to clipboard" className="clickme" onClick={() => CopyToClipboardAndAlert(props.text, props.title)}> <FontAwesomeIcon icon={faPaste} /> <u>{props.text}</u></span>
}

export const APIMetaDataDisplay: React.FC<{ data: BaseApiResponseMetaData }> = props => {
    const { branchName, branchgroupName, errorOccured, errorReason, transactions, transactionID, requestReceivedTime, requestTitle, responseSentTime, sessionID, userName, webName, flightAPINode } = props.data;
    // const [errorReport, setErrorReport] = useState(errorOccured ? `The request aborted due to an error.` : "The request did not abort due to an error, but I have another issue: \n");
    const [showReport, setShowReport] = useState(false);
    const [showReporting, setShowReporting] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    function FormatDate(date: Date): string {
        let result = "";
        result = moment(date).format("DD.MM.YYYY HH:mm");
        return result;
    }
    function ToggleDetails() {
        setShowDetails(!showDetails);
    }
    function ToggleReporting() {
        setShowReporting(!showReporting);
    }
    function ToggleReport() {
        setShowReport(!showReport);
    }

    // const [userErrorDescription, setUserErrorDescription] = useState();
    const reportIntro = `### Auto generated bug report for AgentPlus "${requestTitle}" ###

TransactionID:      ${transactionID}
Username:           ${userName}
Web:                ${webName}
Date:               ${FormatDate(new Date())}
    `;
    const reportOutro = `### End of Bug report ###`;
    const mailTitle = `AgentPlus Bug Report from User ${userName} in Web ${webName} for ${requestTitle} Request`;
    const reportString = `
    ${errorReason ? `
The following error occured:${ReportStringTransaction(errorReason)}` : ""}

Additional information
Branch:             ${branchName}
Branchgroup:        ${branchgroupName}
Agent Environment:  ${Environment}
FlightAPI Node:     ${flightAPINode}
SessionID:          ${sessionID}
Request:            ${requestTitle}
Status:             ${errorOccured ? "Error" : "Success"}
Request received:   ${FormatDate(requestReceivedTime)}
Response sent at:   ${FormatDate(responseSentTime)}
    `;

    function ReportStringTransaction(action: TransactionInfo): string {
        const status: string = action.success ? "Success" : "ERROR";


        const result: string = `
Action:        ${action.title}
Source:        ${action.source}
Status:        ${status}
Error code:    ${action.errorCode}
Error Message: ${action.errorMessage}
Start time:    ${FormatDate(action.startTime)}
End time:      ${FormatDate(action.endTime)}
${action.details.length > 0 && action.details}`;
        return result;
    }
    function Mail() {
        window.location.href = `mailto:support@hitchhiker.com?subject=${encodeURI(mailTitle)}&body=${encodeURI(finalReportString)}`;
        // window.location.href = `mailto:support@hitchhiker.com?subject=AgentPlus%20Bug%20Report&body=${errorReport.replace("/[\r\n]+/g,", mailbodyNewLine)}`;
    }




    const finalReportString = `${reportIntro}${reportString}${reportOutro}`;
    return <div className="card">
        {/* <div className="card-header card-header-primary">"" Request information </div> */}
        <div className="card-body">
            <div className="row">
                <div className="col-12 mb-2">
                    <Alert color="warning">
                        <b className="font-weight-bold">Please always provide the bug report</b> when reporting an issue. You can copy the report with one of the options below.
                    </Alert>
                </div>
                <div className="col-12 mb-2">
                    Transaction ID: <span className="font-weight-bold"><TextWithCopyOption text={transactionID} title="Transaction ID" /></span>
                </div>
                {errorReason ?
                    <div className="col-12 mb-2">
                        <APIPerformedActionComponent data={errorReason} />
                    </div>
                    : <div className="col-12 mb-2">
                        <p>There seems to be no issue with this request.</p>
                    </div>
                }
                <div className="col-12 mb-2">
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button type="button" className="btn btn-primary font-weight-bold" onClick={() => { CopyToClipboardAndAlert(finalReportString, "Bug report") }}><FontAwesomeIcon icon={faPaste} /> Copy bug report to clipboard</button>
                        <button type="button" className="btn btn-secondary" onClick={Mail}><FontAwesomeIcon icon={faEnvelope} /> Send bug report via Mail</button>
                        <button type="button" className="btn" onClick={ToggleReport}><FontAwesomeIcon icon={faSearch} /> Show bug report</button>
                        <button type="button" className="btn" onClick={ToggleDetails}><FontAwesomeIcon icon={faList} /> {showDetails ? "Hide" : "Show"} details...</button>
                    </div>

                </div>
                <div className="col-12">
                    <Collapse isOpen={showReport}>
                        <p>Bug report</p>
                        <textarea className="form-control" rows={10} value={finalReportString} onChange={() => { }} onFocus={(e) => { e.target.select() }}></textarea>
                    </Collapse>
                </div>
                <div className="col-12">
                    <Collapse isOpen={showDetails}>
                        <Fragment>


                            <div className="card">
                                <div className="card-header card-header-primary">Details</div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <div className="table-responsive">

                                                <table className="table table-sm">
                                                    <tbody>
                                                        <tr>
                                                            <td className="font-weight-bold">Username</td>
                                                            <td>{userName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-weight-bold">Branch</td>
                                                            <td>{branchName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-weight-bold">Branchgroup</td>
                                                            <td>{branchgroupName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-weight-bold">Web</td>
                                                            <td>{webName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-weight-bold">TransactionID</td>
                                                            <td><TextWithCopyOption text={transactionID} title="Transaction ID" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-weight-bold">Session ID</td>
                                                            <td><TextWithCopyOption text={sessionID} title="Session ID" /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-weight-bold">FlightAPI Node</td>
                                                            <td>{flightAPINode}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <APIPerformedActionTable data={transactions} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </Fragment>
                    </Collapse>


                </div>

            </div>



        </div>



    </div>
}

const APIPerformedActionComponent: React.FC<{ data: TransactionInfo }> = ({ data }) => {
    const { endTime, details, errorCode, errorMessage, source, startTime, success, title } = data;
    return <div className="card">

        <div className="card-body">
            <div className={`card-title`}>
                <FontAwesomeIcon icon={success ? faCheckCircle : faBug} /> {source} - {title}
            </div>
            <div className="row">
                <div className="col-12 mb-1">
                    Status: {success ? <span style={{ color: "green" }}>Success</span> : <span style={{ color: "red" }}>
                        ERROR: {errorMessage} ({errorCode})
                    </span>}
                </div>

                {details.length > 0 &&
                    <div className="col-12 mb-2">
                        <div className="row">
                            <div className="col">
                                {details}
                            </div>
                        </div>
                    </div>
                }
            </div>

        </div>
    </div>
}
const APIPerformedActionTable: React.FC<{ data: TransactionInfo[] }> = props => {
    return <div>
        <div className="row">
            <div className="col-12 mb-2">Actions performed in request</div>
            {props.data.map((e, index) =>
                <div key={"act_" + index} className="col-12 mb-2">
                    <APIPerformedActionComponent data={e} />
                </div>
            )}
        </div>
    </div>
    // <table className="table table-sm">
    //     <thead>
    //         <tr>
    //             <th>Status</th>
    //             <th>Source</th>
    //             <th>Title</th>
    //             <th>Error code</th>
    //             <th>Error Message</th>
    //             <th>TransactionID</th>
    //         </tr>
    //     </thead>
    //     <tbody>
    //         {props.data.map((e, index) =>
    //             <tr key={index}>
    //                 <td>{e.success ? "Success" : "Failure"}</td>
    //                 <td>{e.source}</td>
    //                 <td>{e.title}</td>
    //                 <td>{e.errorCode}</td>
    //                 <td>{e.errorMessage}</td>
    //                 <td><TextWithCopyOption text={e.transactionID} title="Transaction ID" /></td>

    //             </tr>
    //         )}
    //     </tbody>
    // </table>
}
