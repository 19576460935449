import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { faServicestack } from "@fortawesome/free-brands-svg-icons";

interface specialFareData {
    specialFareData: any
}

const FareBookingSpecial: React.FC<specialFareData> = ({ specialFareData }) => {
    const sendPageHeight1 = () => {
        const height = 800;
        window.parent.postMessage(["setHeight", height + "px"], "*");
    };

    const sendPageHeight = () => {
        setTimeout(() => {
            const height = document.body.scrollHeight;
            window.parent.postMessage(["setHeight", height + "px"], "*");
        }, 500);
    };

    useEffect(() => {
        sendPageHeight1();
        sendPageHeight();
    }, [])
    return (
        <>
            {
                specialFareData?.salesPreferenceDisplayNameHtml?.length > 0 &&
                specialFareData?.salesPreferenceDisplayNameCode?.length > 0 && (
                    <div className="card mx-2 mb-3">
                        <div
                            className="d-flex blueCard cursor-pointer align-items-center px-3 py-2 clickme"
                        >
                            <div className="card-header-primary d-flex align-items-center w-100 ">
                                <FontAwesomeIcon icon={faServicestack} /> &nbsp;
                                <span
                                    className="specialCollapseHeader"
                                    dangerouslySetInnerHTML={{
                                        __html: specialFareData?.salesPreferenceDisplayNameCode,
                                    }}
                                ></span>
                            </div>
                        </div>

                        <Collapse isOpen={true}>
                            <div className="card-body card-body-primary">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: specialFareData?.salesPreferenceDisplayNameHtml,
                                    }}
                                ></div>
                            </div>
                        </Collapse>
                    </div>
                )
            }
        </>
    )
}

export default FareBookingSpecial;
